import Vue from 'vue'

export const MaxHeightMixin = Vue.extend({
  data() {
    return {
      maxHeight: 0
    }
  },

  computed: {
    maxHeightString() {
      return this.maxHeight ? `${this.maxHeight}px` : ''
    },
    maxHeightStyle() {
      return this.maxHeight
        ? { height: this.maxHeightString, maxHeight: this.maxHeightString }
        : {}
    }
  },

  mounted() {
    this.checkMaxHeight()
    window.addEventListener('resize', this.checkMaxHeight)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkMaxHeight)
  },

  methods: {
    getMaxHeightElement() {
      return undefined
    },
    getMaxHeightEndElement() {
      return undefined
    },
    checkMaxHeight() {
      const maxHeightElement = this.getMaxHeightElement()
      if (!maxHeightElement) {
        this.$console.debug(
          'No element found to check max height for, skipping max height check'
        )
        return
      }
      const maxHeightEndElement = this.getMaxHeightEndElement()

      const from = maxHeightElement.getBoundingClientRect()
      const to = maxHeightEndElement
        ? maxHeightEndElement.getBoundingClientRect()
        : document.body.getBoundingClientRect()

      if (!from || !to) {
        this.$console.debug(
          "Couldn't get bounding client rect for elements, skipping max height check"
        )
        return
      }

      this.maxHeight = to.height - (from.top - to.top)
    }
  }
})
