<template>
  <div class="analytics-container">
    <div class="analytics-top">
      <TextInput
        v-model="searchQuery"
        :disabled="analyticsLoading"
        placeholder="Search analytics data..."
        class="analytics-top-search"
        left-icon="search"
      />
      <Button
        text="Export to CSV"
        :disabled="analyticsLoading"
        :loading="exportLoading"
        @click="handleExport"
      />
    </div>
    <div ref="analyticswrapper" class="analytics-wrapper">
      <Table
        v-if="analytics && analytics.length > 0 && !analyticsLoading"
        :items="filteredAnalytics"
        :headers="headers"
        :max-height="maxHeightString"
        default-sort-by="sessions"
      />
      <div v-else-if="analyticsLoading" class="analytics-loading">
        <b-loading active :is-full-page="false"></b-loading>
      </div>
      <div v-else class="analytics-empty">
        <p class="analytics-empty-text">No analytics data could be found</p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateWTime } from '@/util'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import Table from '@c/library/Table.vue'
import TextInput from '@c/library/TextInput.vue'
import Button from '@c/library/Button.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Analytics',
  components: {
    Table,
    Button,
    TextInput
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    analytics: [],
    analyticsLoading: false,
    searchQuery: '',
    exportLoading: false
  }),
  computed: {
    ...mapGetters(['canWriteMembers', 'currentWorkspace']),
    headers() {
      return [
        {
          id: 'user',
          title: 'User',
          key: (user) => this.$umodel.full_name(user),
          sortAsc: (a, b) => {
            const aName = this.$umodel.full_name(a)
            const bName = this.$umodel.full_name(b)
            return aName.localeCompare(bName)
          },
          sortDesc: (a, b) => {
            const aName = this.$umodel.full_name(a)
            const bName = this.$umodel.full_name(b)
            return bName.localeCompare(aName)
          }
        },
        {
          id: 'first_seen',
          title: 'First seen',
          key: (user) => formatDateWTime(user.first_seen),
          sortAsc: (a, b) => {
            const aDate = new Date(a.first_seen)
            const bDate = new Date(b.first_seen)
            return aDate - bDate
          },
          sortDesc: (a, b) => {
            const aDate = new Date(a.first_seen)
            const bDate = new Date(b.first_seen)
            return bDate - aDate
          }
        },
        {
          id: 'last_seen',
          title: 'Last seen',
          key: (user) => formatDateWTime(user.last_seen),
          sortAsc: (a, b) => {
            const aDate = new Date(a.last_seen)
            const bDate = new Date(b.last_seen)
            return aDate - bDate
          },
          sortDesc: (a, b) => {
            const aDate = new Date(a.last_seen)
            const bDate = new Date(b.last_seen)
            return bDate - aDate
          }
        },
        {
          id: 'sessions',
          title: 'Sessions',
          key: (user) => user.sessions,
          sortAsc: (a, b) => a.sessions - b.sessions,
          sortDesc: (a, b) => b.sessions - a.sessions
        },
        {
          id: 'days_active',
          title: 'Days active',
          key: (user) => user.days_active,
          sortAsc: (a, b) => a.days_active - b.days_active,
          sortDesc: (a, b) => b.days_active - a.days_active
        }
      ]
    },
    filteredAnalytics() {
      return this.analytics.filter((item) => {
        return this.headers.some((header) => {
          return (
            String(header.key(item))
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) > -1
          )
        })
      })
    }
  },
  created() {
    this.getAnalyticsData()
    if (
      !this.currentWorkspace ||
      this.currentWorkspace.uuid !== this.$route.params.workspace_id
    ) {
      this.setCurrentWorkspace(this.$route.params.workspace_id)
    }
    if (!this.canWriteMembers) this.$router.push({ name: 'home' })
  },
  methods: {
    ...mapActions(['getWorkspaceAnalytics', 'setCurrentWorkspace']),
    getMaxHeightElement() {
      return this.$refs.analyticswrapper
    },
    async getAnalyticsData() {
      try {
        this.analyticsLoading = true
        this.analytics = await this.getWorkspaceAnalytics({
          workspace_id: this.$route.params.workspace_id
        })
      } catch (e) {
        this.$console.debug('Analytics error', e)
        this.$toast.error(e, 'fetching analytics')
      } finally {
        this.analyticsLoading = false
      }
    },
    async handleExport() {
      this.exportLoading = true
      try {
        const data = await this.getWorkspaceAnalytics({
          workspace_id: this.$route.params.workspace_id,
          export_filetype: 'csv'
        })
        const blob = new Blob([data], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        const m = this.$moment(new Date())
        a.download = `uman_analytics_${m.format('DD-MM-YYYY-HH-mm-ss')}.csv`
        a.click()
      } catch (e) {
        this.$console.debug('Analytics export error', e)
        this.$toast.danger(e, 'exporting analytics')
      } finally {
        this.exportLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.analytics {
  &-container {
    width: 100%;
  }

  &-top {
    padding: 2.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &-search {
      width: min(100%, 30rem);
    }
  }

  &-wrapper {
    padding: 0 2.5rem;
  }

  &-loading {
    height: 15vh;
    position: relative;
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 2.5rem;

    &-text {
      font-size: 1.25rem;
    }

    &-subtext {
      font-size: 1rem;
      color: #60666b;
    }
  }
}
</style>
