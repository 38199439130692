var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "analytics-container" }, [
    _c(
      "div",
      { staticClass: "analytics-top" },
      [
        _c("TextInput", {
          staticClass: "analytics-top-search",
          attrs: {
            disabled: _vm.analyticsLoading,
            placeholder: "Search analytics data...",
            "left-icon": "search"
          },
          model: {
            value: _vm.searchQuery,
            callback: function($$v) {
              _vm.searchQuery = $$v
            },
            expression: "searchQuery"
          }
        }),
        _c("Button", {
          attrs: {
            text: "Export to CSV",
            disabled: _vm.analyticsLoading,
            loading: _vm.exportLoading
          },
          on: { click: _vm.handleExport }
        })
      ],
      1
    ),
    _c(
      "div",
      { ref: "analyticswrapper", staticClass: "analytics-wrapper" },
      [
        _vm.analytics && _vm.analytics.length > 0 && !_vm.analyticsLoading
          ? _c("Table", {
              attrs: {
                items: _vm.filteredAnalytics,
                headers: _vm.headers,
                "max-height": _vm.maxHeightString,
                "default-sort-by": "sessions"
              }
            })
          : _vm.analyticsLoading
          ? _c(
              "div",
              { staticClass: "analytics-loading" },
              [
                _c("b-loading", {
                  attrs: { active: "", "is-full-page": false }
                })
              ],
              1
            )
          : _c("div", { staticClass: "analytics-empty" }, [
              _c("p", { staticClass: "analytics-empty-text" }, [
                _vm._v("No analytics data could be found")
              ])
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }